@media screen and (max-width: 800px) {
  html,body {
    min-width: 1400px;
  }
}
html{
  font-size:10px;
  line-height: 1;
}
.App {
  background: linear-gradient(to bottom,#f0f6ff,#fcf7fc);
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.btn{

}
.scoll-top{
  position: fixed;
  bottom:40px;
  right:1%;
  cursor: pointer;
}
ol, ul {
  padding-left: 0;
  list-style: none;
}
.clearfix::after{
  content: "";
  clear: both;
  height: 0;
  display: block;
  visibility: hidden;
  font-size: 0;
}
.btn1{
  border-radius: 20px;
  color:#ffffff;
  background: linear-gradient(to right,#6a4de1,#7eb0f1);
  width: 149px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
}
.btn1 span{
  color:#ffffff;
}
.f-biger{
  font-size:54px;
  font-weight: 800;
}
.f-big{
  font-size: 30px;
  font-weight: 600;
}
.f-middle{
  font-size: 22px;
}
.f-mini{
  font-size: 18px;
}
.f-miner{
  font-size: 16px;
}
.weight8{
  font-weight: 800 !important;
}
.video-play{
  width: 52px;
  height: 52px;
  background: url(assets/video-icon.png) no-repeat center center;
  background-size: 52px 52px;
  opacity: .8;
  
}
.juzhong{
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.content-wrapper{
  margin: 0px auto;
  width: 1200px;
}
.main-header{
  padding: 30px 0;
}
.header-left{
  float: left;
}
.left-logo-container{
  height: 60px;
  width: 60px;
  margin-right: 30px;
  float: left;
  display: flex;
  align-content: center;
  justify-content: center;
}
.left-logo{
  width: 52px;
  height: 51px;
  margin-top: 4px;
  margin-right: 30px;
}
.left-logo img{
  width: 52px;
  height: 51px;
}
.left-menu{
  display: flex;
  float:left;
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  font-weight: 800;
}
.left-menu .menu-item{
  flex:1;
  padding-right: 25px;
  position: relative;
  z-index: 999;
  text-align: center;
  cursor: pointer;
}
.left-menu .menu-item:hover .menu-text{
  background-image: linear-gradient(180deg,#6a4de1,#7eb0f1);
  background-clip: text;
  -webkit-background-clip: text;

  color: transparent;
}
.menu-item.wiki{
  width: 170px;
  padding-right: 30px;
}
.left-menu .menu-item a{
  color: #000000;
}
.menu-item-cat{
  position: absolute;
  background-color:#fff ;
  font-weight: normal;
  padding: 10px 15px 0px 15px;

  display: none;
}
.left-menu .menu-item:hover .menu-item-cat,.left-menu .menu-item .menu-item-cat:hover{
  display: block;
}
.menu-item-cat .cat-item{
  height: 20px;
  line-height: 20px;
  padding-bottom: 10px;
  box-sizing: content-box;
}

.header-right{
  float: right;
  width: 440px;
}
.header-right .btn1{
  padding: 0 18px;
  box-sizing: content-box;
  float: right;
}
.header-right .header-right-link{
  float:right;
  padding: 8px 20px;
}

.header-right .header-right-link .link-item{
  width: 34px;
  height: 34px;
  background:url(assets/header-icon1.png) no-repeat center center;
  margin-right: 8px;
  float: left;
  cursor: pointer;
}
.header-right .header-right-link .link-item2{
  background:url(assets/header-icon2.png) no-repeat center center;
}
.header-right .header-right-link .link-item3{
  background:url(assets/header-icon3.png) no-repeat center center;
}
.header-right .header-right-link .link-item4{
  background:url(assets/header-icon4.png) no-repeat center center;
}
.header-right .header-right-link .link-item5{
  background:url(assets/header-icon5.png) no-repeat center center;
  border-radius: 9px;
}
/* 时间轴 */
.timeline-title{
  margin-bottom: 60px;
  text-align: center;
}
.about2{
  padding-bottom:60px
}
.about2 ul{
  background:url(assets/column-line.png) repeat-y center
}
.clearfix-time {
  display: block;
  zoom: 1;
}
.clearfix-time:after {
  content: '\20';
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.about2 .tit h6{
  color:#666
}
.about2 .tit h5{
  color:#333;
  margin:15px 0 45px
}

.about2 li .circle{
  background: url(assets/timeline-circle.png) no-repeat center center;
  right:-90px;
  top:50%;
  transform: translateY(-50%);
  left:auto;
  content:"";
  display:block;
  position:absolute;
  width:62px;
  height:62px;
  line-height: 62px;
  text-align: center;
  z-index: 222;
}
.about2 li.active .circle{
  background: url(assets/time-circle-active.png) no-repeat center center;
  font-size: 0px;
}
.about2 li h4{
  position:relative;
  display:block;
  text-decoration:none;
  margin-bottom: 0px;
}

.about2 li.active h4, .about2 li.active p{
  color:#ffffff;
}
.about2 li:nth-child(2n) .circle{
  left:-90px;
  content:"";
  display:block;
  position:absolute;
  width:62px;
  height:62px;
  line-height: 62px;
  text-align: center;
}
.about2 li h4{
  color:#000000;
}
.about2 li p{
  color:#000000;
}
.about2 li:nth-child(1){
  margin-top:0
}
.about2 li{
  float:left;
  width:540px;
  margin-right: 61px;
  text-align:left;
  position:relative;
  margin-top:0;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 30px;
  box-shadow: 0px 1px 15px 0px rgba(132,132,132,0.12);
}
.about2 li.active{
  color:#ffffff;
  background: linear-gradient(to right,#6a4de1,#7eb0f1);
  color:#ffffff;
  box-shadow: -2px 2px 15px 1px rgba(132,132,132,0.12);

}
.about2 li:nth-child(2n){
  float:right;
  text-align:left;
  margin-right: 0;
  margin-left: 61px;
}
/* 我的团队 */
.team-title{
  text-align: center;
  font-weight: 800;
  margin-bottom: 60px;
}
.team-silder{
  padding-bottom: 60px;
}
.team-silder-item{
  padding:0 45px;
  position:relative;
}
.mendershow-con .team-silder-item{
  padding:0 10px;
}
.team-silder-item-main{
  background-color: #ffffff;
  border-radius: 15px;
  padding: 30px 20px;
  cursor: pointer;
}

.team-silder-item .team-silder-item-main >div{
  text-align: center;
}
.team-silder-img{
 
}
.team-silder-img img,.team-silder-img video{
  width: 240px;
  height: 240px;
}
.team-silder-name{
  text-align: center;
  line-height: 1;
  padding-bottom: 10px;
  color:#333333;
}
.team-silder-item-main .team-silder-img{
  padding-bottom: 10px;
  position: relative;
}
.team-silder-item-main .team-silder-name{
  text-align: center;

}
.team-silder .slick-prev {
  left: 35px;
}
.team-silder .slick-next {
  right: 35px;
}
.team-silder-desc img{ 
  width: 28px;
  margin:0 auto;
}
.team-con .slick-prev:before,.team-con .slick-next:before{
  content: '';
}

.team-con .slick-prev {
  left: 35px;
  width: 52px;
  height: 52px;
  opacity: .8;
  background-image: url(assets/left-fill.png?v=4);
}
.team-con .slick-next {
  right: 35px;
  width: 52px;
  height: 52px;
  background-image: url(assets/right-fill.png?v=3);
  opacity: .8;
}
.team-con .team-silder-item{
  padding: 0 30px;
}
.mendershow-con .slick-prev{
  left: 25px;
  width: 52px;
  height: 52px;
  background-image: url(assets/left-fill.png);
  
}
.mendershow-con .slick-next{
  right: 25px;
  width: 52px;
  height: 52px;
  background-image: url(assets/right-fill.png);
  
}
.mendershow-con .slick-prev:before,.mendershow-con .slick-next:before {
  content: '';
}
.mendershow-con .slick-next{
  right: 25px;
}
.team-silder .slick-prev, .team-silder .slick-next{
  z-index: 999;
  top:calc(50% - 20px)
}

/* 首页问答模块 */
.question-con{
  margin-bottom: 60px;
}
.question-title{
  width: 100%;
  padding: 0 0 60px 50%;

}
.question-body{
  display: flex;
}
.question-body .q-flex-item{
  flex:1;
}
.question-item-line1{
  flex:1;
  display: flex;
}
.question-body .q-img{
  display: flex;
  align-content: center;
  justify-content: center;
}
.question-body .q-img img{
  width: 500px;
  height: 421px;
}
.question-list .question-item{
  background-color: #f0f6ff;
  margin-bottom: 20px;
  border-radius: 20px;

}
.question-body .question-list .question-item .question-item-line1{
  padding:0 20px;
  border-radius: 15px;
}
.question-body .question-list .question-item .question-item-line2{
  padding:20px 20px;
  display: none;
}
.question-body .question-list .question-item.active .question-item-line2{
  display: block;
}
.question-body .question-list .question-item.active .question-item-line1{
  background: linear-gradient(to right,#6a4de1,#7eb0f1);
}
.question-body .question-list .question-item .question-item-line3{
    height: 1px;
    width: calc(100% - 40px);
    background: linear-gradient(to right,#6a4de1,#7eb0f1);
    display:none;
    margin: 0 20px;
}
.question-body .question-list .question-item.active .question-item-line3{
  display:block;
}
.question-item .question-item-wen{
  flex:1;
  height:64px;
  line-height: 64px;
}
.question-item.active .question-item-wen{
  color:#ffffff;
}
.question-item-icon{
  width:64px;
  height:64px;
  line-height: 64px;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
}
.question-item .question-item-icon .imgc{
  width: 34px;
  height: 34px;
  line-height: 34px;
  background: linear-gradient(to bottom,#6a4de1,#7eb0f1);
  border-radius: 50%;
  background-image: url(assets/right-icon.png);
  margin: 15px 0;
}
.question-item.active .question-item-icon .imgc{
  background-image: url(assets/xia-icon.png);
}
.bottom-left{
  width: 100%; 
  box-sizing: border-box;
  text-align: center;
  padding-bottom: 60px;
}
.bottom-left-line1{
  width: 60px;
  height: 60px;
  margin: 0 auto 20px auto;
}
.bottom-left-line1 img{
  width: 100%;
} 
.bottom-left-line2{
  font-weight: 800;
  letter-spacing:1px;
  margin-bottom:20px;
}
.bottom-left-line3{
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  align-content: center;
  display: flex;
}
.bottom-left-line3 .btn1{
  float:right;
}
.bottom-left-line3 .header-right{
  width: auto;
}

.bottom-right{
  float:left;
}
.mint-con{
  width: 1194px;
  height: 503px;
  margin-bottom: 60px;
  position: relative;
  display: flex;
}
.mint-left{
  width: 700px;
  position: relative;
}
.mint-context{
  /* background: url(assets/mint-text.png) no-repeat center center; */
  width: 700px;
  height: 298px;
  padding-top:60px;
}
.p10{
  padding-top:15px
}
.mint-context .mint-context-line{
  font-size: 42px;
  font-weight: 600;
  line-height: 1.5;
}
.mint-context .mint-context-line2{
  line-height:1.5;
  font-weight:400;
  font-size:18px;
}
.mint-context .mint-context-line .linear{
  background-image: linear-gradient(90deg,#6a4de1,#7eb0f1);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}
.mint-right{
  flex:1;
}
.mint-btn-c{
  padding-top:60px;
}
.mint-con .btn1{
  font-size: 18px;
  padding: 8px 31px;
  box-sizing: content-box;
  font-weight: 600;
  float: left;
  margin-right: 20px;
}
.mint-cal{
  float:left;
  height: 50px;
  line-height: 50px;
  text-align: center;
  padding: 8px 31px;
}
.mint-cal >span{
  display:inline-block;
  padding: 0 8px;
  font-size: 32px;
}
.mint-cal >span.fh{
  cursor: pointer;
}
.mint-con .btn1.btn-play{
  display: flex;
  align-content: center;
  justify-content: center;
  background-clip:padding-box,border-box;
  background-origin:padding-box,border-box;
  background-image:linear-gradient(135deg,#ffffff,#ffffff),linear-gradient(135deg,#6a4de1,#7eb0f1);
  border:2px transparent solid;
  cursor: pointer;
  
}
.mint-play{
  background: url(assets/video-play-icon.png) no-repeat center center;
  width: 34px;
  height: 34px;
  float: left;
  margin-top: 8px;
  margin-right: 8px;
}
.mint-play-text{
  float: left;
  background-image: linear-gradient(180deg,#6a4de1,#7eb0f1);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
 
}

.mint-right{
  width: 500px;
  height: 523px;
}
.mint-slider-item .img{
  width: 500px;
  height: 523px;
}

/* 视频页 */
.video-wrapper,.image-p{
  padding-bottom: 40px;
}
.video-wrapper .team-silder-item,.image-p .team-silder-item{
  width: 300px;
  float:left;
  padding: 0 10px;
  margin-bottom: 20px;
}
/* 币种分析工具 */
.coin-analysis{
  width: 100%;
}
.container-warp{
  min-width: 1200px;
  margin: 0 auto;
  width: 90%;
}
.coin-analysis .form{
  width: 100%;
  text-align: center;
  padding: 30px 0;
}
.coin-data-table tr:nth-child(2n){
  background: #cfe7ff;
}
.loading-modal{
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #000000;
  opacity: .8;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-items: center;
  text-align: center;
}
.loading-icon{
  flex: 1;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute!important;
}
.coin-analysis .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
  padding: 16px 10px;
}
.download-table-xls-button{
  display: none;
}
.connect-parent{
  position: relative;
}
.connect-parent .connect-modal{
  position: absolute;
  left:-67.5px;
  top:50px;
  z-index: 10!important;
  margin-top: 12px;
  width: 320px;
  background-color: #ffffff;
   border-radius:10px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)!important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)!important;
  box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow,0 0 #0000)!important;
  --tw-ring-color: rgb(0 0 0/var(--tw-ring-opacity))!important;
  --tw-ring-opacity: 0.05!important;

}
.connect-parent .connect-modal .connect-inner{
  padding: 16px 20px;
  word-wrap: break-word;
}
.wallet-details{
  display: inline-flex!important;
  align-items: center!important;
  width: 100%;
  box-sizing: border-box;
  
}
.details-address{
  overflow: hidden;
  text-overflow:ellipsis; 
  white-space:nowrap; 
  color: #000;
  cursor: pointer;
}
.logout-button{
  float: none!important;
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box!important;
}
.copy{
  cursor: pointer;
}

/* 恐慌度 */
.fear-con{
  display: inline-block;
  border: 3px solid red;
  box-sizing: border-box;
  height: 32px;
  padding: 0px 10px;
  border-radius: 5px;
  line-height: 26px;
  margin-left: 30px;
  color:red;
  font-weight: 600;
}
.fear-con .fear-item{
  margin-right: 10px;
}

/* 事件解析页 */
.incident-content{
  width: 100%;
  background-color: #ffffff;
  position: relative;
}

.main {
    width: 812px;
}
.kx-list .outer {
  position: relative;
  margin-bottom: 20px;
}
.kx-date {
  width: 46px;
  height: 57px;
  background: linear-gradient(to bottom,#6a4de1,#7eb0f1);
  border-radius: 5px;
  border: 1px solid #4b74c7;
  margin-bottom: 25px;
  position: relative;
  text-align: center;
}
.kx-date .month {
  color: #fff;
  font-size: 14px;
  height: 28px;
  line-height: 28px;
}
.kx-date .date {
  color: #2859bd;
  font-size: 22px;
  height: 27px;
  line-height: 28px;
  background: #fff;
  border-radius: 0 0 5px 5px;
}
.kx-date .week {
  position: absolute;
  width: 42px;
  font-size: 20px;
  left: 55px;
  top: 0;
  font-weight: bold;
}
.kx-date .week p {
  font-size: 12px;
  color: #999;
  margin-top: 3px;
  font-weight: normal;
}
.kx-list .item {
  padding-left: 68px;
  border-left: #efeff4 1px solid;
  margin-left: 16px;
  position: relative;
}
.kx-list .point {
  position: absolute;
  left: -5px;
  top: 0;
  width: 10px;
  height: 10px;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSIxMHB4IiB2aWV3Qm94PSIwIDAgMTAgMTAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+aWNvbi/lv6vorq/lnIbngrk8L3RpdGxlPgogICAgPGcgaWQ9IuaOp+S7tiIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IndlYummlumhtS/lv6vorq/mqKHlnZcv5pi+56S65Lik6KGMIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTIuMDAwMDAwLCAtNy4wMDAwMDApIj4KICAgICAgICAgICAgPGcgaWQ9Imljb24v5b+r6K6v5ZyG54K5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMi4wMDAwMDAsIDcuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8Y2lyY2xlIGlkPSLmpK3lnIblvaIiIGZpbGw9IiNDOERBRkYiIGN4PSI1IiBjeT0iNSIgcj0iNSI+PC9jaXJjbGU+CiAgICAgICAgICAgICAgICA8Y2lyY2xlIGlkPSLmpK3lnIblvaLlpIfku70iIGZpbGw9IiM2RTk5RjEiIGN4PSI1IiBjeT0iNSIgcj0iMyI+PC9jaXJjbGU+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
}
.kx-list .pubtime {
  font-size: 12px;
  position: absolute;
  left: 20px;
  top: -4px;
}
.kx-list .content {
  position: relative;
  top: -28px;
  border-bottom: 1px solid #efeff4;
  padding-bottom: 20px;
  padding-top: 20px;
}
.kx-list .n-title {
  font-size: 16px;
  margin: 5px 0;
  font-weight: 700;
  line-height: 30px;
  color: red;
  display: block;
}
.kx-list .n-builder {
  font-size: 14px;
  line-height: 20px;
  color: #888;
  display: block;
}
.sidebar {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding-bottom: 20px;
  width: 350px;
}

.newest-floor {
  margin-top: 20px;
  border: 1px solid #EFEFF4;
  border-radius: 5px;
  padding: 12px 11px 20px;
  margin-top: 0;
}
.side-title {
  font-size: 20px;
  line-height: 1;
  height: 50px;
  padding-top: 12px;
  font-weight: 700;
}
.article-list .article-item {
  font-size: 16px;
  font-weight: bold;
  display: block;
  padding-left: 26px;
  line-height: 1.4;
  margin-bottom: 20px;
  padding-bottom: 5px;
  position: relative;
  cursor: pointer;
}
.article-list .article-item:before {
  font-size: 24px;
  position: absolute;
  left: 4px;
  top: -6px;
  font-style: italic;
}
.article-list .article-item:nth-child(1)::before {
  content: '1';
  color: #FF963F;
}
.article-list .article-item:nth-child(2)::before {
  content: '2';
  color: #FF963F;
}
.article-list .article-item:nth-child(3)::before {
  content: '3';
  color: #FF963F;
}
.article-list .article-item:nth-child(4)::before {
  content: '4';
  color: #CCCCD3;
}
.article-list .article-item:nth-child(5)::before {
  content: '5';
  color: #CCCCD3;
}
.article-list .article-item:nth-child(6)::before {
  content: '6';
  color: #CCCCD3;
}
.article-list a.article-item:nth-child(7)::before {
  content: '7';
  color: #CCCCD3;
}
.article-list a.article-item:nth-child(8)::before {
  content: '8';
  color: #CCCCD3;
}
.article-list .article-item:nth-child(9)::before {
  content: '9';
  color: #CCCCD3;
}
.article-list .article-item:nth-child(10)::before {
  content: '10';
  color: #CCCCD3;
}
.article-list .article-item:nth-child(11)::before {
  content: '11';
  color: #CCCCD3;
}
.article-list .article-item:nth-child(12)::before {
  content: '12';
  color: #CCCCD3;
}
.article-list .article-item:nth-child(13)::before {
  content: '13';
  color: #CCCCD3;
}
.article-list .article-item:nth-child(14)::before {
  content: '14';
  color: #CCCCD3;
}
.article-list .article-item:nth-child(15)::before {
  content: '15';
  color: #CCCCD3;
}

.zl-title{
  padding-bottom:38px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 60px;
  background-color: #ffffff;
  box-shadow: 0 -5px 5px rgb(0 0 0 / 10%);
}
.zl-title h2 {
  font-size: 46px;
  line-height: 42px;
  font-weight: bold;
}
.zl-title p {
  font-size: 18px;
  line-height: 25px;
  color: #999;
  margin-top: 10px;
}